$(document).ready(function(){
	
	$('.testimonial').slick({
		dots: false,			
		autoplay: false,
		autoplaySpeed: 4000,
		adaptiveHeight: true,
		fade: false,
		focusOnSelect: false,
		arrows: true,
		slidesToScroll: 1,
		slidesToShow: 1,
	});
	
	$('.slider2').slick({
		dots: false,			
		autoplay: false,
		autoplaySpeed: 4000,
		adaptiveHeight: true,
		fade: false,
		focusOnSelect: false,
		arrows: true,
		slidesToScroll: 1,
		slidesToShow: 1,
	});
	
  });
	$(document).scroll(function() {
	  
		var $elem = $('footer');
        var $window = $(window);

        var docViewTop = $window.scrollTop();
        var docViewBottom = docViewTop + $window.height();

        var elemTop = $elem.offset().top;
        var elemBottom = elemTop + $elem.height();

        if (elemTop >= (docViewBottom + 20) || (elemTop + $('#cta').height()) >= (docViewBottom + 134) ) {
            $('#cta').css('position', 'fixed');
        } else {
            $('#cta').css({'position' : 'relative'});
			
        }
	});